<template>

  <!-- Formulário -->
  <form @submit.prevent="chamaConfirmacao" class="formulario-container">
    <!-- Primeira linha do Form -->
    <div class="d-md-flex">
      <div class="formulario-item">
        <label for="razaoSocial" class="formulario-item-label">Razão Social</label>
        <input type="text" v-model="autorizada.razaoSocial" name="razaoSocial"
          :class="{ 'invalid-field': verificarSeHaItem('razaoSocial', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('razaoSocial', this.erros, 'field')">
          {{ verificarItemArray('razaoSocial', this.erros, 'field').message }}
        </p>
      </div>

      <div class="formulario-item">
        <label for="nomeFantasia" class="formulario-item-label">Nome Fantasia</label>
        <input type="text" v-model="autorizada.nomeFantasia" name="nomeFantasia"
          :class="{ 'invalid-field': verificarSeHaItem('nomeFantasia', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('nomeFantasia', this.erros, 'field')">
          {{ verificarItemArray('nomeFantasia', this.erros, 'field').message }}
        </p>
      </div>

      <div class="formulario-item">
        <label for="cnpj" class="formulario-item-label">CNPJ</label>
        <input type="text" v-model="autorizada.cnpj" v-mask="'##.###.###/####-##'" name="cnpj"
          :class="{ 'invalid-field': verificarSeHaItem('cnpj', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('cnpj', this.erros, 'field')">
          {{ verificarItemArray('cnpj', this.erros, 'field').message }}
        </p>
      </div>

      <div class="formulario-item">
        <label for="SAP" class="formulario-item-label">SAP</label>
        <input type="text" v-model="autorizada.codigoSAP" v-mask="'##########'" name="SAP"
          :class="{ 'invalid-field': verificarSeHaItem('sap', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('sap', this.erros, 'field')">
          {{ verificarItemArray('sap', this.erros, 'field').message }}
        </p>
      </div>
    </div>

    <!-- Segunda linha do Form -->
    <div class="d-md-flex">
      <div class="formulario-item">
        <label for="cep" class="formulario-item-label">CEP</label>
        <input type="text" v-model="autorizada.cep" placeholder="" name="cep" v-mask="'##.###-###'"
          @blur="buscaCep(autorizada.cep, autorizada, preencherRegiaoAut)"
          :class="{ 'invalid-field': verificarSeHaItem('cep', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('cep', this.erros, 'field')">
          {{ verificarItemArray('cep', this.erros, 'field').message }}
        </p>
      </div>

      <div class="formulario-item f-4">
        <label for="endereco" class="formulario-item-label">Endereco</label>
        <input type="text" v-model="autorizada.endereco" name="endereco"
          :class="{ 'invalid-field': verificarSeHaItem('endereco', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('endereco', this.erros, 'field')">
          {{ verificarItemArray('endereco', this.erros, 'field').message }}
        </p>
      </div>

      <div class="formulario-item f-1">
        <label for="numero" class="formulario-item-label">Numero</label>
        <input type="text" v-model="autorizada.numero" name="numero"
          :class="{ 'invalid-field': verificarSeHaItem('numero', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('numero', this.erros, 'field')">
          {{ verificarItemArray('numero', this.erros, 'field').message }}
        </p>
      </div>

      <div class="formulario-item">
        <label for="complemento" class="formulario-item-label">Complemento</label>
        <input type="text" v-model="autorizada.complemento" name="complemento" />
      </div>

    </div>

    <!-- Terceira linha do Form -->
    <div class="d-md-flex">
      <div class="formulario-item">
        <label for="cidade" class="formulario-item-label">Cidade</label>
        <input type="text" v-model="autorizada.cidade" name="cidade"
          :class="{ 'invalid-field': verificarSeHaItem('cidade', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('cidade', this.erros, 'field')">
          {{ verificarItemArray('cidade', this.erros, 'field').message }}
        </p>
      </div>

      <div class="formulario-item">
        <label for="bairro" class="formulario-item-label">Bairro</label>
        <input type="text" v-model="autorizada.bairro" name="bairro"
          :class="{ 'invalid-field': verificarSeHaItem('bairro', this.erros, 'field') }" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('bairro', this.erros, 'field')">
          {{ verificarItemArray('bairro', this.erros, 'field').message }}
        </p>
      </div>

      <div :class="{ 'f-1': editar, 'f-2': !editar }" class="formulario-item">
        <label class="formulario-item-label">UF</label>
        <multiselect @input="preencherRegiaoAut"
          :class="{ 'invalid-field-multiselect': verificarSeHaItem('uf', this.erros, 'field') }" v-model="autorizada.uf"
          label="descricao" track-by="id" :options="regioesBr" group-values="estados" group-label="descricao"
          :searchable="true" :multiple="false" placeholder="Selecione" select-label="Selecionar"
          deselect-label="Remover" selected-label="Selecionado"><span slot="noResult">Item não encontrado</span>
        </multiselect>
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('uf', this.erros, 'field')">
          {{ verificarItemArray('uf', this.erros, 'field').message }}
        </p>
      </div>

      <div v-if="editar" class="formulario-item f-2">
        <label for="dataAtivacaoCadastro" class="formulario-item-label">Data Cadastro</label>
        <datepicker v-model="autorizada.dataCadastro" :language="ptBR" disabled />
      </div>
    </div>

    <!-- Quarta linha do Form -->
    <div class="d-md-flex">
      <div class="formulario-item">
        <label for="tipo" class="formulario-item-label">Tipo</label>
        <multiselect :class="{ 'invalid-field-multiselect': verificarSeHaItem('autorizadaTipo', this.erros, 'field') }"
          v-model="autorizada.autorizadaTipoId" label="descricao" track-by="descricao" :options="tipo"
          :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
          deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('autorizadaTipo', this.erros, 'field')">
          {{ verificarItemArray('autorizadaTipo', this.erros, 'field').message }}
        </p>
      </div>
      <div class="formulario-item">
        <label for="regiao" class="formulario-item-label">Região</label>
        <multiselect
          :class="{ 'invalid-field-multiselect': verificarSeHaItem('autorizadaRegiao', this.erros, 'field') }"
          v-model="autorizada.autorizadaRegiaoId" label="descricao" track-by="descricao" :options="regiao"
          :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
          deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
        <p class="input-erro-mensagem"
          v-if="erros.length && verificarSeHaItem('autorizadaRegiao', this.erros, 'field')">
          {{ verificarItemArray('autorizadaRegiao', this.erros, 'field').message }}
        </p>
      </div>
      <div class="formulario-item">
        <label for="grupo" class="formulario-item-label">Grupo</label>
        <multiselect :class="{ 'invalid-field-multiselect': verificarSeHaItem('autorizadaGrupo', this.erros, 'field') }"
          v-model="autorizada.autorizadaGrupoId" label="descricao" track-by="descricao" :options="grupo"
          :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
          deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
        <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('autorizadaGrupo', this.erros, 'field')">
          {{ verificarItemArray('autorizadaGrupo', this.erros, 'field').message }}
        </p>
      </div>
      <div class="formulario-item">
        <label for="status" class="formulario-item-label">Status</label>
        <multiselect
          :class="{ 'invalid-field-multiselect': verificarSeHaItem('autorizadaStatus', this.erros, 'field') }"
          v-model="autorizada.ativo" label="descricao" track-by="id" :options="opcoes" :searchable="false"
          :multiple="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover"
          selected-label="Selecionado" :allow-empty="true" />
        <p class="input-erro-mensagem"
          v-if="erros.length && verificarSeHaItem('autorizadaStatus', this.erros, 'field')">
          {{ verificarItemArray('autorizadaStatus', this.erros, 'field').message }}
        </p>
      </div>
    </div>

    <div class="container-btns margin-top">
      <button type="button" class="btn btn-cancelar" @click.prevent="voltar">Voltar</button>
      <button type="submit" class="btn btn-laranja btn-padding">Salvar</button>
    </div>

  </form>

</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import store from "@/store";
import { opcoes, brEstados } from '@/utils/json'
import Multiselect from "vue-multiselect";
import { functions, strings, objects, requests } from '@/assets/js'
import { listarDetalhesAutorizada, listarTipoAutorizada, listarGrupo, listarRegioes, atualizarAutorizada, cadastrarAutorizada } from "@/services/autorizadas";

export default {

  components: {
    Datepicker,
    Multiselect,
  },

  name: "FormularioAutorizadas",
  props: {
    editar: {
      required: true,
      type: Boolean
    },
  },

  data() {
    return {
      ptBR: ptBR,
      idAutorizada: this.$route.params.id,
      opcoes: opcoes.status,
      regioesBr: brEstados.ufRegiao,
      erros: [],
      autorizada: {
        dataCadastro: "",
        razaoSocial: "",
        nomeFantasia: "",
        cnpj: "",
        codigoSAP: "",
        cep: "",
        endereco: "",
        numero: "",
        complemento: "",
        cidade: "",
        bairro: "",
        uf: "",
        autorizadaTipoId: [],
        autorizadaRegiaoId: [],
        autorizadaGrupoId: [],
        ativo: [],
      },
      objReferencia: {},
      tipo: [],
      regiao: [],
      grupo: [],
    }
  },

  async mounted() {
    await Promise.all([
      listarRegioes().then(resp => {
        this.regiao = resp.data
      }),
      listarGrupo().then(resp => {
        this.grupo = resp.data
      }),
      listarTipoAutorizada().then(resp => {
        this.tipo = resp.data.filter(tipo => tipo.id != 2 && tipo.id != 4)
      }),
    ])
    this.popularDadosEditar()
  },

  methods: {
    buscaCep: requests.buscaCep,
    verificarSeHaItem: functions.verificarSeHaItem,
    verificarItemArray: functions.verificarItemArray,
    popularDadosEditar() {
      if (this.editar) {
        listarDetalhesAutorizada(this.idAutorizada).then(resp => {
          this.autorizada = resp.data.resposta
          this.popularObjReferencia()
        })
      }
    },
    preencherRegiaoAut() {
      for (let i = 0; i < this.regioesBr.length; i++) {
        const
          regiao = this.regioesBr[i],
          { estados } = regiao
        estados.filter(item => {
          if (item.descricao == this.autorizada.uf.descricao) return this.autorizada.autorizadaRegiaoId = regiao
        })
      }
    },
    tratarDadosAutorizada() {
      this.autorizada.ativo = functions.verificarStatus(this.autorizada.ativo);
      this.autorizada.uf = functions.verificarUf(this.autorizada.uf)
      this.autorizada.autorizadaRegiaoId = this.verificarItemArray(this.autorizada.autorizadaRegiaoId, this.regiao, 'id')
      this.autorizada.autorizadaGrupoId = this.verificarItemArray(this.autorizada.autorizadaGrupoId, this.grupo, 'id')
      this.autorizada.autorizadaTipoId = this.verificarItemArray(this.autorizada.autorizadaTipoId, this.tipo, 'id')
    },
    popularObjReferencia() {
      for (const key in this.autorizada) {
        const element = this.autorizada[key];
        this.objReferencia[key] = element
      }
      this.tratarDadosAutorizada()
    },
    voltar() {
      this.$router.push('/autorizadas/listagem')
    },
    formValid() {
      this.erros = []

      if (!functions.verificarDadoValido(this.autorizada.razaoSocial)) {

        this.erros.push({ field: "razaoSocial", message: "Razão Social é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.nomeFantasia)) {
        this.erros.push({ field: "nomeFantasia", message: "Nome Fantasia é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.cnpj)) {
        this.erros.push({ field: "cnpj", message: "O CNPJ é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.codigoSAP)) {
        this.erros.push({ field: "sap", message: "O código SAP é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.endereco)) {
        this.erros.push({ field: "endereco", message: "O endereço é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.numero)) {
        this.erros.push({ field: "numero", message: "O número é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.bairro)) {
        this.erros.push({ field: "bairro", message: "O bairro é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.cidade)) {
        this.erros.push({ field: "cidade", message: "A cidade é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.cep)) {
        this.erros.push({ field: "cep", message: "O cep é obrigatório" });
      }

      if (this.autorizada.cep.length < 8 || this.autorizada.cep.length > 10 || strings.removerCaracteresEspeciais(this.autorizada.cep).length != 8) {
        this.erros.push({ field: "cep", message: "Formato inválido" });
        store.commit("SET_MODAL", {
          ativado: true,
          mensagem: 'CEP deverá conter 8 dígitos numéricos',
          tipo: "erro",
        })
      }

      if (!functions.verificarDadoValido(this.autorizada.uf)) {
        this.erros.push({ field: "uf", message: "UF é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.autorizadaTipoId)) {
        this.erros.push({ field: "autorizadaTipo", message: "O tipo é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.autorizadaRegiaoId)) {
        this.erros.push({ field: "autorizadaRegiao", message: "A região é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.autorizadaGrupoId)) {
        this.erros.push({ field: "autorizadaGrupo", message: "O grupo é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.autorizada.ativo)) {
        this.erros.push({ field: "autorizadaStatus", message: "O status é obrigatório" });
      }

      if (this.erros.length) {
        return false;
      }

      return true;
    },
    formData() {
      const data = new FormData();

      data.append('autorizadaGrupoId', this.autorizada.autorizadaGrupoId.id)
      data.append('autorizadaRegiaoId', this.autorizada.autorizadaRegiaoId.id)
      data.append('autorizadaTipoId', this.autorizada.autorizadaTipoId.id)
      data.append('codigoSAP', this.autorizada.codigoSAP)
      data.append('razaoSocial', this.autorizada.razaoSocial)
      data.append('nomeFantasia', this.autorizada.nomeFantasia)
      data.append('cnpj', strings.removerCaracteresEspeciais(this.autorizada.cnpj))
      data.append('cep', strings.removerCaracteresEspeciais(this.autorizada.cep))
      data.append('uf', this.autorizada.uf.descricao)
      data.append('endereco', this.autorizada.endereco)
      data.append('numero', this.autorizada.numero)
      data.append('complemento', this.autorizada.complemento)
      data.append('bairro', this.autorizada.bairro)
      data.append('cidade', this.autorizada.cidade)
      data.append('ativo', this.autorizada.ativo.value)

      return data;
    },
    salvarDadosAutorizada() {
      this.editar ?
        atualizarAutorizada(this.idAutorizada, this.formData()) :
        cadastrarAutorizada(this.formData()).then(() => {
          this.voltar()
        })
    },
    chamaConfirmacao() {
      if (!this.formValid()) { // Caso tenha campo inválido ou não preenchido
        return;
      } else if (objects.comparacaoObjForm(this.objReferencia, this.formData()) && this.editar) {
        // Para editar os dados deverão ser modificados
        store.commit("SET_MODAL", {
          ativado: true,
          mensagem: 'Não houve nenhuma mudança nos dados',
          tipo: "erro",
        })
      } else {
        // Modal
        const config = {
          ativado: true,
          tipo: "confirmacao",
          mensagem: `Tem certeza que deseja ${this.editar ? 'alterar' : 'cadastrar'} os dados ?`,
          func: this.salvarDadosAutorizada
        }
        store.commit("SET_MODAL_MENSAGEM", config);
      }
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />